import * as React from 'react';
import * as sectionStyles from '../styles/section.module.css';

const Section = ({
  backgroundImage, children, customStyle, className, id,
}) => (
  <section id={id} className={`${className} ${sectionStyles.parallax} ${sectionStyles.sectionBg}`} style={{ backgroundImage: `url(${backgroundImage})`, position: 'relative', ...customStyle }}>
    {children}
  </section>
);

export default Section;
