import * as React from 'react';
import * as sectionStyles from '../styles/section.module.css';

const SectionContentContainer = ({ children, customStyle, className }) => (
  <div className={`${className} ${sectionStyles.contentContainer} container-fluid`} style={customStyle}>
    {children}
  </div>
);

export default SectionContentContainer;
